import { useState, useEffect } from "react"
import { doc, onSnapshot } from "firebase/firestore"
import { db } from "../../../firebase/firebase"

function useStories(familyId, storyId) {
  const [chapters, setChapters] = useState([])
  const [processingState, setProcessingState] = useState("idle")

  useEffect(() => {
    console.log("Setting up real-time listener", familyId, storyId)
    let unsubscribe

    if (familyId && storyId) {
      const storyDocRef = doc(db, "advenchore", familyId, "stories", storyId)

      unsubscribe = onSnapshot(
        storyDocRef,
        (storyDocSnap) => {
          if (storyDocSnap.exists()) {
            const storyData = storyDocSnap.data()
            const chapters = storyData.chapters || []
            const processingState = storyData.processing || "idle"
            setProcessingState(processingState)
            setChapters(chapters)
          } else {
            console.log("No such document!")
          }
        },
        (error) => {
          console.error("Error listening to document: ", error)
        }
      )
    }

    return () => {
      if (unsubscribe) {
        unsubscribe()
      }
    }
  }, [familyId, storyId])

  return { chapters, processingState }
}

export default useStories
