import { Paper, Typography, Divider, Button, Box } from "@mui/material"

const ChapterCard = ({ chapter }) => {
  const splitChapters = chapter?.text?.split("\n\n")
  return (
    <Paper elevation={3} sx={{ padding: 2 }}>
      <Typography variant="h4" gutterBottom>
        {chapter.title || ""}
      </Typography>
      <Divider sx={{ marginBottom: 2 }} />
      {splitChapters?.map((text, index) => (
        <Typography key={index} variant="body1" paragraph>
          {text}
        </Typography>
      ))}
      {/* <Box sx={{ marginTop: 4 }}>
        <Typography variant="h6">What should they do next?</Typography>
        <Button variant="contained" color="primary" sx={{ marginRight: 2 }}>
          Climb the Mountain
        </Button>
        <Button variant="contained" color="secondary">
          Explore the Valley
        </Button>
      </Box> */}
    </Paper>
  )
}

export default ChapterCard
