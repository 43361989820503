import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import SiteWrapper from '../../home-pages/SiteWrapper';
import {
  Avatar,
  Box,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material"
import { useState } from "react"
import { Helmet } from "react-helmet"
import { prettyFormatDate } from "./util"
import EventCard from "./EventCard"
import RichEvenCard from "./RichEventCard"

const eventsData = [
  {
    name: "Jack",
    date: "2025-01-06",
    avatar: "placeholder_avatar.jpg",
    cardImage: "images/timeline/jack-birthday.jpg",
    category: "birthday",
    family: "Blanchard",
  },
  {
    name: "Miles",
    date: "2012-03-19",
    avatar: "placeholder_avatar.jpg",
    cardImage: "images/timeline/miles-birthday.jpg",
    category: "birthday",
    family: "Albretsen",
  },
  {
    name: "Logan",
    date: "2016-04-04",
    avatar: "placeholder_avatar.jpg",
    cardImage: "images/timeline/logan-birthday.jpg",
    category: "birthday",
    family: "Albretsen",
  },
  {
    name: "Sadie",
    date: "1998-04-05",
    avatar: "placeholder_avatar.jpg",
    cardImage: "images/timeline/sadie-birthday.jpg",
    category: "birthday",
    family: "MDymock",
  },
  {
    name: "Connor",
    date: "2011-04-11",
    avatar: "placeholder_avatar.jpg",
    cardImage: "images/timeline/connor-birthday.jpg",
    category: "birthday",
    family: "RDymock",
  },
  {
    name: "Shirlene",
    date: "1957-04-11",
    avatar: "placeholder_avatar.jpg",
    cardImage: "images/timeline/shirlene-birthday.jpg",
    category: "birthday",
    family: "PDymock",
  },
  {
    name: "Kenneth",
    date: "2023-04-16",
    avatar: "placeholder_avatar.jpg",
    cardImage: "images/timeline/kenneth-birthday.jpg",
    category: "birthday",
    family: "Albretsen",
  },
  {
    name: "Olivia",
    date: "2020-04-28",
    avatar: "placeholder_avatar.jpg",
    cardImage: "images/timeline/olivia-birthday.jpg",
    category: "birthday",
    family: "Albretsen",
  },
  {
    name: "Marie",
    date: "2016-05-08",
    avatar: "placeholder_avatar.jpg",
    cardImage: "images/timeline/marie-birthday.jpg",
    category: "birthday",
    family: "RDymock",
  },
  {
    name: "Natalie",
    date: "2015-05-09",
    avatar: "placeholder_avatar.jpg",
    cardImage: "images/timeline/natalie-birthday.jpg",
    category: "birthday",
    family: "Albretsen",
  },
  {
    name: "Kellianne",
    date: "1986-05-15",
    avatar: "placeholder_avatar.jpg",
    cardImage: "images/timeline/kellianne-birthday.jpg",
    category: "birthday",
    family: "Albretsen",
  },
  {
    name: "Leah",
    date: "1986-06-05",
    avatar: "placeholder_avatar.jpg",
    cardImage: "images/timeline/leah-birthday.jpg",
    category: "birthday",
    family: "RDymock",
  },
  {
    name: "Beckham",
    date: "2022-06-23",
    avatar: "placeholder_avatar.jpg",
    cardImage: "images/timeline/beckham-birthday.jpg",
    category: "birthday",
    family: "MDymock",
  },
  {
    name: "Ryan",
    date: "1983-07-05",
    avatar: "placeholder_avatar.jpg",
    cardImage: "images/timeline/ryan-birthday.jpg",
    category: "birthday",
    family: "RDymock",
  },
  {
    name: "Heidi & Zak",
    date: "2017-07-15",
    avatar: "placeholder_avatar.jpg",
    cardImage: "images/timeline/hz-anniversary.jpg",
    category: "anniversary",
    family: "Kandare",
  },
  {
    name: "Jake",
    date: "2023-07-18",
    avatar: "placeholder_avatar.jpg",
    cardImage: "images/timeline/jake-birthday.jpg",
    category: "birthday",
    family: "Kandare",
  },
  {
    name: "Matthew",
    date: "1998-07-20",
    avatar: "placeholder_avatar.jpg",
    cardImage: "images/timeline/matthew-birthday.jpg",
    category: "birthday",
    family: "MDymock",
  },
  {
    name: "Amy & Ethan",
    date: "2017-08-11",
    avatar: "placeholder_avatar.jpg",
    cardImage: "images/timeline/amye-anniversary.jpg",
    category: "anniversary",
    family: "Blanchard",
  },
  {
    name: "Zak",
    date: "1994-08-16",
    avatar: "placeholder_avatar.jpg",
    cardImage: "images/timeline/zak-birthday.jpg",
    category: "birthday",
    family: "Kandare",
  },
  {
    name: "Kellianne & Keith",
    date: "2010-08-21",
    avatar: "placeholder_avatar.jpg",
    cardImage: "images/timeline/kk-anniversary.jpg",
    category: "anniversary",
    family: "Albretsen",
  },
  {
    name: "Paul & Shirlene",
    date: "1982-08-30",
    avatar: "placeholder_avatar.jpg",
    cardImage: "images/timeline/ps-anniversary.jpg",
    category: "anniversary",
    family: "PDymock",
  },
  {
    name: "Matthew & Sadie",
    date: "2020-09-03",
    avatar: "placeholder_avatar.jpg",
    cardImage: "images/timeline/ms-anniversary.jpg",
    category: "anniversary",
    family: "MDymock",
  },
  {
    name: "Ethan",
    date: "1995-09-11",
    avatar: "placeholder_avatar.jpg",
    cardImage: "images/timeline/ethan-birthday.jpg",
    category: "birthday",
    family: "Blanchard",
  },
  {
    name: "Dottie",
    date: "2022-09-28",
    avatar: "placeholder_avatar.jpg",
    cardImage: "images/timeline/dottie-birthday.jpg",
    category: "birthday",
    family: "Blanchard",
  },
  {
    name: "Samantha",
    date: "2007-10-03",
    avatar: "placeholder_avatar.jpg",
    cardImage: "images/timeline/samantha-birthday.jpg",
    category: "birthday",
    family: "RDymock",
  },
  {
    name: "Lucy",
    date: "2024-10-11",
    avatar: "placeholder_avatar.jpg",
    cardImage: "images/timeline/lucy-birthday.jpg",
    category: "birthday",
    family: "Kandare",
  },
  {
    name: "Heidi",
    date: "1990-10-18",
    avatar: "placeholder_avatar.jpg",
    cardImage: "images/timeline/heidi-birthday.jpg",
    category: "birthday",
    family: "Kandare",
  },
  {
    name: "Ford",
    date: "2020-10-22",
    avatar: "placeholder_avatar.jpg",
    cardImage: "images/timeline/ford-birthday.jpg",
    category: "birthday",
    family: "Blanchard",
  },
  {
    name: "Ryan & Leah",
    date: "2006-11-18",
    avatar: "placeholder_avatar.jpg",
    cardImage: "images/timeline/rl.jpg",
    category: "anniversary",
    family: "RDymock",
  },
  {
    name: "Keith",
    date: "1985-11-19",
    avatar: "placeholder_avatar.jpg",
    cardImage: "images/timeline/keith-birthday.jpg",
    category: "birthday",
    family: "Albretsen",
  },
  {
    name: "Rue",
    date: "2018-12-03",
    avatar: "placeholder_avatar.jpg",
    cardImage: "images/timeline/rue-birthday.jpg",
    category: "birthday",
    family: "Blanchard",
  },
  {
    name: "Amy",
    date: "1994-12-10",
    avatar: "placeholder_avatar.jpg",
    cardImage: "images/timeline/amy-birthday.jpg",
    category: "birthday",
    family: "Blanchard",
  },
  {
    name: "Paul",
    date: "1958-12-29",
    avatar: "placeholder_avatar.jpg",
    cardImage: "images/timeline/paul-birthday.jpg",
    category: "birthday",
    family: "PDymock",
  },
  {
    name: "Adelaide",
    date: "2024-04-29",
    avatar: "placeholder_avatar.jpg",
    cardImage: "images/timeline/adelaide-birthday.jpg",
    category: "birthday",
    family: "MDymock",
  },
  {
    name: "Sadie Mission Homecoming",
    date: "February 2019",
    avatar: "placeholder_avatar.jpg",
    cardImage: "images/timeline/sadie-mission-home.jpg",
    category: "mission",
    family: "MDymock",
  },
  {
    name: "Sadie Mission Departure",
    date: "July 2017",
    avatar: "placeholder_avatar.jpg",
    cardImage: "images/timeline/sadie-mission-departure.jpg",
    category: "mission",
    family: "MDymock",
  },
  {
    name: "Kellianne Mission Departure",
    date: "June 2007",
    avatar: "placeholder_avatar.jpg",
    cardImage: "images/timeline/kellianne-mission-departure.jpg",
    category: "mission",
    family: "Albretsen",
  },
  {
    name: "Kellianne Mission Homecoming",
    date: "November 2008",
    avatar: "placeholder_avatar.jpg",
    cardImage: "images/timeline/kellianne-mission-home.jpg",
    category: "mission",
    family: "Albretsen",
  },
  {
    name: "Ryan Mission Departure",
    date: "August 7, 2002",
    avatar: "placeholder_avatar.jpg",
    cardImage: "images/timeline/ryan-mission-departure.jpg",
    category: "mission",
    family: "Albretsen",
  },
  {
    name: "Ryan Mission Homecoming",
    date: "August 9, 2004",
    avatar: "placeholder_avatar.jpg",
    cardImage: "images/timeline/ryan-mission-home.jpg",
    category: "mission",
    family: "Albretsen",
  },
  {
    name: "Paul Mission Departure",
    date: "March 1, 1978",
    avatar: "placeholder_avatar.jpg",
    cardImage: "images/timeline/paul-mission-departure.jpg",
    category: "mission",
    family: "PDymock",
  },
  {
    name: "Paul Mission Homecoming",
    date: "March 1980",
    avatar: "placeholder_avatar.jpg",
    cardImage: "images/timeline/paul-mission-home.jpg",
    category: "mission",
    family: "PDymock",
  },
]

const FamilyTimeline = () => {
  // State for sorting mode: 'absolute' or 'monthDay'
  const [sortMode, setSortMode] = useState("absolute")
  // Multi-select state for event category filtering.
  const [selectedCategories, setSelectedCategories] = useState([
    "anniversary",
    "birthday",
    "mission",
  ])
  // Multi-select state for family filtering.
  const [selectedFamilies, setSelectedFamilies] = useState([
    "PDymock",
    "Kandare",
    "Blanchard",
    "RDymock",
    "MDymock",
    "Albretsen",
  ])

  // Handlers for the toggle groups
  const handleSortChange = (event, newSortMode) => {
    if (newSortMode !== null) {
      setSortMode(newSortMode)
    }
  }

  const handleCategoryChange = (event, newCategories) => {
    // newCategories is an array (or null); default to an empty array if null.
    setSelectedCategories(newCategories || [])
  }

  const handleFamilyChange = (event, newFamilies) => {
    setSelectedFamilies(newFamilies || [])
  }

  // Filter events based on category and family selections.
  const filteredData = eventsData.filter(
    (event) =>
      selectedCategories.includes(event.category) &&
      selectedFamilies.includes(event.family)
  )

  // Sort data based on chosen sort mode.
  const sortedData = [...filteredData].sort((a, b) => {
    if (sortMode === "absolute") {
      return new Date(a.date) - new Date(b.date)
    } else {
      // Sort by month and day, ignoring the year.
      const aDate = new Date(a.date)
      const bDate = new Date(b.date)
      const aMonth = aDate.getMonth()
      const bMonth = bDate.getMonth()
      const aDay = aDate.getDate()
      const bDay = bDate.getDate()
      if (aMonth === bMonth) {
        return aDay - bDay
      }
      return aMonth - bMonth
    }
  })

  return (
    <SiteWrapper title="Family Timeline">
      <Helmet>
        <title>Timeline</title>
      </Helmet>
      <Box sx={{ mb: 2, display: "flex", flexDirection: "column", gap: 2 }}>
        {/* Sorting Toggle Group */}
        <ToggleButtonGroup
          value={sortMode}
          exclusive
          onChange={handleSortChange}
          aria-label="Sort Mode"
        >
          <ToggleButton value="absolute" aria-label="Absolute Date">
            Absolute Date
          </ToggleButton>
          <ToggleButton value="monthDay" aria-label="Month/Day">
            Month/Day
          </ToggleButton>
        </ToggleButtonGroup>

        {/* Category Multi-Select Toggle Group */}
        <ToggleButtonGroup
          value={selectedCategories}
          onChange={handleCategoryChange}
          aria-label="Event Category"
        >
          <ToggleButton value="anniversary" aria-label="Anniversary">
            Anniversary
          </ToggleButton>
          <ToggleButton value="birthday" aria-label="Birthday">
            Birthday
          </ToggleButton>
          <ToggleButton value="mission" aria-label="Mission">
            Mission
          </ToggleButton>
        </ToggleButtonGroup>

        {/* Family Multi-Select Toggle Group */}
        <ToggleButtonGroup
          value={selectedFamilies}
          onChange={handleFamilyChange}
          aria-label="Family"
        >
          <ToggleButton value="PDymock" aria-label="PDymock">
            PDymock
          </ToggleButton>
          <ToggleButton value="RDymock" aria-label="RDymock">
            RDymock
          </ToggleButton>
          <ToggleButton value="Albretsen" aria-label="Albretsen">
            Albretsen
          </ToggleButton>
          <ToggleButton value="Kandare" aria-label="Kandare">
            Kandare
          </ToggleButton>
          <ToggleButton value="Blanchard" aria-label="Blanchard">
            Blanchard
          </ToggleButton>
          <ToggleButton value="MDymock" aria-label="MDymock">
            MDymock
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>

      {/* Timeline Section */}
      <Timeline position="alternate">
        {sortedData.map((event, index) => (
          <TimelineItem key={index}>
            <TimelineSeparator>
              <TimelineDot>
                <Avatar alt={event.name} src={event.avatar} />
              </TimelineDot>
              {index < sortedData.length - 1 && <TimelineConnector />}
            </TimelineSeparator>
            <TimelineContent>
              <EventCard event={event} />
              {/* <RichEvenCard event={event} /> */}
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
    </SiteWrapper>
  )
}

export default FamilyTimeline;