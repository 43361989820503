import React, { useEffect, useMemo } from "react"

// MUI Components
import {
  Container,
  Grid,
  Typography,
  Box,
  Paper,
  Button,
  Avatar,
  List,
  Link,
  ListItem,
  ListItemAvatar,
  ListItemText,
  LinearProgress,
  Divider,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  ToggleButton,
  ToggleButtonGroup,
  Dialog,
  CircularProgress,
  Tooltip,
  Fab,
} from "@mui/material"

// MUI Icons
import LockIcon from "@mui/icons-material/Lock"
import LockOpenIcon from "@mui/icons-material/LockOpen"

// Custom Components
import SiteWrapper from "../../home-pages/SiteWrapper"
import ChapterCard from "./ChapterCard"

// External Libraries
import { useSnackbar } from "notistack"
import { motion } from "framer-motion"
import { useSelector } from "react-redux"

// API
import { useDispatch } from "react-redux"
import {
  generateNewChapter,
  initializeNewStory,
  updatePointTotals,
  resetStoryState,
  setChapterData,
  setStoryId,
} from "../../../redux/storySlice"
import CharactersCard from "./CharactersCard"
import useFamilyData from "./useFamilyData"
import { getAuth } from "firebase/auth"
import useFamilyId from "./useFamilyId"
import useStories from "./useStories"
import { useNavigate } from "react-router-dom"
import UnlockableFab from "./UnlockableFAB"

const storyStates = ["INIT_STATE", "IN_PROGRESS", "STORY_COMPLETE"]

// using .map make an array of 10 chapters all unlocked
const allChapters = Array.from({ length: 10 }, (_, i) => ({
  id: i + 1,
  title: `Chapter ${i + 1}`,
  status: "unlocked",
}))

const stages = [
  "Init State",
  "Story 1 Ready",
  "Story 2 not yet earned",
  "Final Story ready to be unlocked",
  "All Stories unlocked",
]

const ChapterButton = ({ title, status, variant, onClick }) => {
  return (
    <motion.div
      initial={{ scale: 1 }}
      animate={status === "available" ? { scale: [1, 1.1, 1] } : { scale: 1 }}
      transition={
        status === "available" ? { duration: 2, repeat: Infinity } : {}
      }
    >
      <Button
        variant={variant}
        color={status === "locked" ? "error" : "primary"}
        onClick={onClick}
        startIcon={
          status === "locked" ? (
            <LockIcon />
          ) : status === "available" ? (
            <LockOpenIcon />
          ) : null
        }
        disabled={status === "locked"}
        fullWidth
      >
        <Typography variant="button">{title}</Typography>
      </Button>
    </motion.div>
  )
}

const AdvenchoreStoryPage = () => {
  const [currentChapter, setCurrentChapter] = React.useState(0)
  // const [chapterData, setChapterData] = React.useState(chapters)
  const [currentPoints, setCurrentPoints] = React.useState(0)
  const [characters, setCharacters] = React.useState([])
  const targetPoints = 20

  //confirmation dialog
  const [showDialog, setShowDialog] = React.useState(false)
  const [dialogMessage, setDialogMessage] = React.useState("")
  const [dialogTitle, setDialogTitle] = React.useState("")
  const [dialogCallback, setDialogCallback] = React.useState(() => {})
  const [dialogConfirmText, setDialogConfirmText] = React.useState("Unlock")

  const dispatch = useDispatch()
  const { chapterData, storyState, loading } = useSelector(
    (state) => state.story
  )

  const auth = getAuth()
  const userId = auth.currentUser.uid

  const familyId = useFamilyId(userId)
  const { currentStoryId, processing } = useFamilyData(familyId)
  const { chapters } = useStories(familyId, currentStoryId)
  const navigate = useNavigate()

  //navigate to the last page when the story is updated
  // useEffect(() => {
  //   setCurrentChapter(chapters.length - 1)
  // }, [chapters])

  useEffect(() => {
    if (chapters && chapters.length > 0) {
      console.log("dispatching setChapterData", chapters)
      dispatch(setChapterData({ chapterData: chapters }))
    }
  }, [chapters])

  useEffect(() => {
    if (currentStoryId) {
      dispatch(setStoryId(currentStoryId))
    }
  }, [currentStoryId])

  const progress = useMemo(
    () => (currentPoints / targetPoints) * 100,
    [currentPoints, targetPoints]
  )

  useEffect(() => {
    dispatch(updatePointTotals({ currentPoints, targetPoints }))
  }, [currentPoints, targetPoints])

  const { enqueueSnackbar } = useSnackbar()

  const handleChapterClick = (chapter, index) => {
    if (chapter.status === "available") {
      setShowDialog(true)
      setDialogTitle("Unlock Chapter?")
      setDialogMessage("Are you sure you want to unlock this chapter?")
      setDialogConfirmText("Unlock")
      setDialogCallback(() => () => handleUnlock(index))
    } else if (chapter.status === "unlocked") {
      setCurrentChapter(index)
    } else {
      enqueueSnackbar(
        "This chapter is locked! Please complete the previous chapters first."
      )
    }
  }

  const onRequestInitNewStory = () => {
    setDialogTitle("Start New Story?")
    setDialogMessage("Are you ready to start a new story?")
    setDialogConfirmText("Start")
    setDialogCallback(() => onInitNewStory)
    setShowDialog(true)
  }

  const onRequestArchiveStory = () => {
    setDialogTitle("Archive Story?")
    setDialogMessage("Save this story so you can start a new one?")
    setDialogConfirmText("Archive")
    setDialogCallback(() => onArchiveStory)
    setShowDialog(true)
  }

  const onArchiveStory = () => {
    console.log("Resetting story!")
    setShowDialog(false)
    dispatch(resetStoryState())
    setCurrentChapter(null)
    enqueueSnackbar("Story archived!")
  }

  const onInitNewStory = () => {
    dispatch(initializeNewStory({ characters }))
    console.log("Initializing new story!")
    enqueueSnackbar("New story initialized!")
    setShowDialog(false)
    // setChapterData(chapters)
    // setCurrentChapter(0)
  }

  const handleUnlock = (index) => {
    console.log("handle unlock")
    dispatch(generateNewChapter())
    setCurrentPoints(0) // this is just for debugging
    setCurrentChapter(index)
    enqueueSnackbar("Chapter unlocked!")
    setShowDialog(false)
  }

  const onSimPointsComplete = () => {
    if (storyState !== "INIT_STATE") {
      setCurrentPoints(targetPoints)
    }
  }

  const onCharacterUpdate = (newCharacters) => {
    setCharacters(newCharacters)
  }

  const navigateToChores = () => {
    navigate("/chores")
  }

  const onClickUnlock = () => {
    dispatch(generateNewChapter())
    setCurrentPoints(0) // this is just for debugging
    enqueueSnackbar("Chapter unlocked!")
  }

  return (
    <SiteWrapper>
      <Button onClick={onSimPointsComplete}>Simulate point completion</Button>
      {/* <Typography variant="h6">User ID: {userId}</Typography>
      <Typography variant="h6">Current Story: {currentStoryId}</Typography>
      <Typography variant="h6">Processing: {processing}</Typography> */}
      <Container maxWidth="lg">
        {/* Chapter Selection Button */}
        <Grid container spacing={2}>
          {chapterData &&
            chapterData.map((chapter, index) => (
              <Grid item key={chapter.id + chapter.title}>
                <ChapterButton
                  title={`Chapter ${index + 1}: ${chapter.title}`}
                  onClick={() => handleChapterClick(chapter, index)}
                  status={chapter.status}
                  variant={index === currentChapter ? "contained" : "outlined"}
                />
              </Grid>
            ))}
        </Grid>

        <Grid container spacing={2} sx={{ marginTop: 2 }}>
          {/* Sidebar with Character Profiles */}
          {storyState === "INIT_STATE" && (
            <Grid item xs={3}>
              <CharactersCard onUpdate={onCharacterUpdate} />
            </Grid>
          )}

          {/* Main Story Content */}
          <Grid item xs>
            {/* If init state display the kickoff button */}
            {storyState === "INIT_STATE" && processing !== "processing" && (
              <Paper
                elevation={3}
                sx={{
                  padding: 2,
                  height: 200,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <motion.div
                  initial={{ scale: 1 }}
                  animate={
                    storyState === "INIT_STATE"
                      ? { scale: [1, 1.1, 1] }
                      : { scale: 1 }
                  }
                  transition={
                    storyState === "INIT_STATE"
                      ? { duration: 2, repeat: Infinity }
                      : {}
                  }
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={onRequestInitNewStory}
                  >
                    {loading
                      ? "Generating Story"
                      : "Ready to Start a New Story!"}
                  </Button>
                </motion.div>
              </Paper>
            )}
            {processing === "processing" && (
              <Paper
                elevation={3}
                sx={{
                  padding: 2,
                  height: 100,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: 2,
                }}
              >
                <Box sx={{ width: "100%" }}>
                  Generating Story...
                  <LinearProgress />
                </Box>
              </Paper>
            )}
            {storyState !== "INIT_STATE" &&
              processing === "idle" &&
              chapterData &&
              chapterData[currentChapter] && (
                <ChapterCard chapter={chapterData[currentChapter]} />
              )}
          </Grid>
        </Grid>

        {storyState !== "INIT_STATE" && processing === "idle" && (
          <Paper
            elevation={3}
            sx={{ padding: 2, marginTop: 2, marginBottom: 2 }}
          >
            <Grid container spacing={2}>
              {storyState !== "STORY_COMPLETE" && (
                <Grid item xs={12}>
                  <Typography variant="subtitle1">
                    Next Chapter Unlock Progress
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    <Link href="/chores" underline="none">
                      Complete chores
                    </Link>{" "}
                    to earn more points!
                  </Typography>
                  <Button variant="contained" onClick={onClickUnlock}>
                    Unlock Next Chapter
                  </Button>
                  <br />
                  {/* Display the current value and total value */}
                  <Typography variant="body2" color="textSecondary">
                    {`${currentPoints}/${targetPoints} points`}
                  </Typography>
                  <LinearProgress variant="determinate" value={progress} />
                </Grid>
              )}
              {storyState === "STORY_COMPLETE" && (
                <Grid item xs={12}>
                  <Typography variant="h6">
                    Congratulations! You've reached the end of the story!
                  </Typography>

                  <Button
                    variant="contained"
                    color="primary"
                    onClick={onRequestArchiveStory}
                  >
                    Archive Story
                  </Button>
                </Grid>
              )}
            </Grid>
          </Paper>
        )}
        <Dialog open={showDialog} onClose={() => setShowDialog(false)}>
          <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {dialogMessage}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowDialog(false)}>Cancel</Button>
            <Button onClick={dialogCallback} autoFocus>
              {dialogConfirmText}
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
      <Tooltip title="Get more points!" arrow>
        <Fab
          color="primary"
          aria-label="chores"
          onClick={navigateToChores}
          style={{ position: "fixed", bottom: 40, right: 40 }}
        >
          <Box
            component="img"
            src={"/images/chores.svg"}
            alt="chores"
            sx={{
              width: 40,
              height: 40,
            }}
          />
        </Fab>
      </Tooltip>
      <UnlockableFab
        targetAmount={targetPoints}
        currentAmount={currentPoints}
        onUnlock={onClickUnlock}
      />
    </SiteWrapper>
  )
}

export default AdvenchoreStoryPage
