import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate, useParams } from 'react-router-dom';
import { auth, useFirebaseAuthentication } from '../firebase/firebase';
import { useSnackbar } from 'notistack';
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useLocation } from 'react-router-dom';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://www.ryandymock.com/">
        RyanDymock.com
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme();

export const SignInPage = (props) => {
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const authUser = useFirebaseAuthentication()

  const loc = useLocation()
  // const redirect = loc.pathname === '/signin' ? "/" : loc.pathname;
  const redirect = "/" // temp fix, come back to this later

  const handleSubmit = (event) => {
    event.preventDefault()
    const data = new FormData(event.currentTarget)
    const email = data.get("email")
    const password = data.get("password")
    signInWithEmailAndPassword(auth, email, password)
      .then((user) => {
        //check for the groupID / familyID here
        //check for family setup completion
        if (user.user.emailVerified) {
          // getUserData(user.user.uid, onUserDataReceived);
          localStorage.setItem("loggedIn", "TRUE")
          // console.log(`redirecting to ${redirect}`)
          navigate(redirect)
        } else {
          showErrorMessage(
            "Please first check your email and verify your registration.  Then try again!"
          )
          // sendConfirmationEmail(user.user); //
          getAuth().signOut()
          navigate("/signIn")
        }
      })
      .catch((error) => {
        handleBadLogin(error)
      })
  }

  if (authUser && authUser.emailVerified) {
    // getUserData(authUser.uid, onUserDataReceived);
  }

  const showErrorMessage = (message) => {
    enqueueSnackbar(message, {
      variant: "error",
      anchorOrigin: {
        vertical: "top",
        horizontal: "right",
      },
    })
  }

  const handleBadLogin = (error) => {
    if (error.code === "auth/invalid-email") {
      showErrorMessage("please enter a valid email address")
    } else if (error.code === "auth/user-not-found") {
      showErrorMessage(
        "Hmm...  We don't recognize that email address, please try again!"
      )
    } else if (error.code === "auth/internal-error") {
      showErrorMessage("Unable to process your request, please try again!")
    } else if (error.code === "auth/wrong-password") {
      showErrorMessage(
        "Hmm...  You may have typed your password incorrectly, please try again!"
      )
    } else {
      showErrorMessage(error.message)
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="/signUp" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  )
}