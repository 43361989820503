import { doc, getDoc, setDoc, updateDoc, deleteDoc } from "firebase/firestore";
import { db } from "../../../firebase/firebase";

  // Retrieve the FamilyID from the user's document
  export const fetchFamilyData = async (familyId) => {
    console.log("Fetching Family Data in API", familyId)
    try {
        console.log('getting reference')
        const familyDocRef = doc(db, "advenchore", familyId);
        console.log('getting snapshot')
        const familyDocSnap = await getDoc(familyDocRef);
        console.log('got snapshot')

        if (familyDocSnap.exists()) {
          return (familyDocSnap.data());
        } else {
          console.log("No such family data!");
          return {}
        }
    } catch (error) {
      console.error("Error fetching family data: ", error);
      return {}
    }
  };

  // Create or Update Family Data
  export const saveFamilyData = async (familyID, newFamilyData) => {
    try {
      const familyDocRef = doc(db, "advenchore", familyID);
      await setDoc(familyDocRef, { members: newFamilyData}, { merge: true });
      console.log("Family data saved/updated successfully!");
    } catch (error) {
      console.error("Error saving family data: ", error);
    }
  };

  // Update specific fields in Family Data
  export const updateFamilyData = async (familyID, updatedFields) => {
    try {
      const familyDocRef = doc(db, "advenchore", familyID);
      await updateDoc(familyDocRef, updatedFields);
      console.log("Family data updated successfully!");
    } catch (error) {
      console.error("Error updating family data: ", error);
    }
  };

  // Delete Family Data
  export const deleteFamilyData = async (familyID) => {
    try {
      const familyDocRef = doc(db, "advenchore", familyID);
      await deleteDoc(familyDocRef);
      console.log("Family data deleted successfully!");
    } catch (error) {
      console.error("Error deleting family data: ", error);
    }
  };

  export const saveCharacters = async (familyId, characters) => {
    try {
      const familyDocRef = doc(db, "advenchore", familyId);
      await setDoc(familyDocRef, { members: characters }, { merge: true });
    } catch (error) {
      console.error("Error saving characters: ", error);
      throw error;
    }
  }