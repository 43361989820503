import {
  Button,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material"
import { Avatar } from "@mui/material"
import React, { useEffect } from "react"
import FamilySetupDialog from "./FamilySetupDialog"
import { useFirebaseAuthentication } from "../../../firebase/firebase"
import { doc, getDoc, setDoc } from "firebase/firestore"
import { db } from "../../../firebase/firebase"
import { fetchFamilyData, saveFamilyData } from "./advenchoreApi"
import { set } from "date-fns"

const CharactersCard = ({ onUpdate }) => {
  const [showDialog, setShowDialog] = React.useState(false)
  const [members, setMembers] = React.useState([])
  const [familyId, setFamilyId] = React.useState(null)

  const authUser = useFirebaseAuthentication()
  const userId = authUser?.uid

  useEffect(() => {
    async function getFamilyData() {
      if (familyId) {
        const data = await fetchFamilyData(familyId) // Fetch family data on component mount
        setMembers(data?.members || [])
      }
    }
    getFamilyData()
  }, [familyId])

  useEffect(() => {
    onUpdate(members)
  }, [members])

  useEffect(() => {
    if (userId) {
      const fetchFamilyData = async () => {
        try {
          const userDocRef = doc(db, "Users", userId)
          const userDocSnap = await getDoc(userDocRef)

          if (userDocSnap.exists()) {
            const userData = userDocSnap.data()
            const familyId = userData.FamilyId
            setFamilyId(familyId)
          } else {
          }
        } catch (error) {
          console.error("Error fetching family data: ", error)
        }
      }

      fetchFamilyData()
    }
  }, [userId])

  //   useEffect(() => {
  //     if (familyId) {
  //       const saveFamilyData = async () => {
  //         try {
  //           const familyDocRef = doc(db, "advenchore",    )
  //           console.log(`Saving family data for familyId: ${familyId}`, {
  //             members,
  //           })
  //           await setDoc(familyDocRef, { members }, { merge: true })
  //           console.log("Family data saved/updated successfully!")
  //         } catch (error) {
  //           console.error("Error saving family data: ", error)
  //         }
  //       }
  //       saveFamilyData()
  //     }
  //   }, [familyId])

  const handleFamilyDialogClose = () => {
    setShowDialog(false)
  }

  const handleFamilyDialogSubmit = async (values) => {
    console.log("Family Dialog Submit", values)
    // save to firebase
    const familyData = await saveFamilyData(familyId, values)
    setMembers(values)
    setShowDialog(false)
  }

  return (
    <Paper elevation={3} sx={{ padding: 2 }}>
      <Typography variant="h6">Characters</Typography>
      <List>
        {members.map((member, index) => (
          <ListItem key={index}>
            <ListItemAvatar>
              <Avatar src={member.avatar} />
            </ListItemAvatar>
            <ListItemText
              primary={`${member.name}`}
              secondary={`${member.role.slice(0, 1).toUpperCase()}${member.role.slice(1)}  (${member.characteristic.toLocaleLowerCase()})`}
            />
          </ListItem>
        ))}
      </List>
      {/* Edit Button */}
      <Button
        variant="outlined"
        onClick={() => setShowDialog(true)}
        color="primary"
      >
        Edit Characters
      </Button>

      <FamilySetupDialog
        open={showDialog}
        onClose={handleFamilyDialogClose}
        onSubmit={handleFamilyDialogSubmit}
        initMembers={members}
        familyId={familyId}
      />
    </Paper>
  )
}

export default CharactersCard
