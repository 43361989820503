import { Box, Card, CardContent, CardMedia, Typography } from "@mui/material"
import { prettyFormatDate } from "./util"

const EventCard = ({ event }) => {
  return (
    <Card sx={{ display: "flex", alignItems: "center" }}>
      <CardMedia
        component="img"
        sx={{ width: 151, height: 151, objectFit: "cover" }}
        image={event.cardImage}
        alt={event.name}
      />
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <CardContent>
          <Typography variant="h5" component="div">
            {event.name}
          </Typography>
          <Typography variant="subtitle1" color="text.secondary">
            {prettyFormatDate(event.date)}
          </Typography>
        </CardContent>
      </Box>
    </Card>
  )
}

export default EventCard
