import * as React from "react"
import Box from "@mui/material/Box"
import CircularProgress from "@mui/material/CircularProgress"
import Fab from "@mui/material/Fab"
import Tooltip from "@mui/material/Tooltip"
import LockIcon from "@mui/icons-material/Lock"
import LockOpen from "@mui/icons-material/LockOpen"
import { green } from "@mui/material/colors"

const UnlockableFab = ({ targetAmount, currentAmount, onUnlock }) => {
  const progress = (currentAmount / targetAmount) * 100
  const isUnlocked = progress >= 100

  const handleClick = () => {
    if (isUnlocked && onUnlock) {
      onUnlock()
    }
  }

  const buttonSx = {
    ...(isUnlocked && {
      bgcolor: green[500],
      "&:hover": {
        bgcolor: green[700],
      },
    }),
  }

  return (
    <Box sx={{ position: "fixed", bottom: 120, right: 40 }}>
      <Tooltip
        title={
          isUnlocked
            ? "Click to unlock"
            : `Get more points to unlock (${currentAmount}/${targetAmount})`
        }
        arrow
      >
        <Fab color="primary" onClick={handleClick} sx={buttonSx}>
          {isUnlocked ? <LockOpen /> : <LockIcon />}
        </Fab>
      </Tooltip>
      <CircularProgress
        variant="determinate"
        value={progress}
        size={68}
        sx={{
          position: "absolute",
          top: -6,
          left: -6,
          zIndex: 1,
          color: isUnlocked ? "green" : "grey",
        }}
      />
    </Box>
  )
}

export default UnlockableFab
