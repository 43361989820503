import { Box, Tab, Tabs, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react"
import ChoresTabPanel from "./ChoresTabPanel"
import { getCurrentUserId } from "../StoryView/api"

const tabsDefinition = [
  {
    label: "Daily",
    frequency: "daily",
    color: "lightgreen",
  },
  {
    label: "Weekly",
    frequency: "weekly",
    color: "lightblue",
  },
  {
    label: "Monthly",
    frequency: "monthly",
    color: "#DDA0DD",
  },
  {
    label: "Self Improvement",
    frequency: "improvement",
    color: "#F08080",
  },
  {
    label: "Screen List",
    frequency: "screens",
    color: "#FFDB58",
  },
  {
    label: "As Needed",
    frequency: "asNeeded",
    color: "#778899",
  },
]

const userIDLookup = {
  CvWOX0v1C4QBN2oHdLCZoNGnuVf2: "Ryan",
  CzcExYjxGyepLSWtUXS1R5Je4Vg2: "Samantha",
  YKWCl65I5NOt7dL2UOI9DTAMGPL2: "Leah",
}

const DesktopChoresView = ({ choresByAssignee }) => {
  const [tabValue, setTabValue] = useState(0)
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"))
  const [assignee, setAssignee] = useState("Ryan")

  useEffect(() => {
    const userId = getCurrentUserId()
    const assignee = userIDLookup[userId]
    if (assignee) {
      setAssignee(assignee)
    } else {
      setAssignee("Ryan")
    }
  }, [])

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue)
  }

  const padding = isMobile ? 0 : 3

  return (
    <Box sx={{ padding, pt: 3, mb: 0, lg: { pt: 4, mb: 10 } }}>
      <Tabs
        value={tabValue}
        onChange={handleChangeTab}
        variant="scrollable"
        TabIndicatorProps={{
          style: {
            top: 0,
          },
        }}
        style={{ elevation: 5, padding: 0 }}
      >
        {tabsDefinition.map((tab, index) => (
          <Tab
            key={`tabkey-${index}`}
            label={tab.label}
            value={index}
            sx={{ backgroundColor: tab.color }}
          />
        ))}
      </Tabs>
      <div
        role="tabpanel"
        hidden={tabValue !== 0}
        key={`frequency-tabpanel-daily`}
      >
        <ChoresTabPanel
          frequency={"daily"}
          setAssignee={setAssignee}
          assignee={assignee}
          choresByAssignee={choresByAssignee}
        />
      </div>
      <div
        role="tabpanel"
        hidden={tabValue !== 1}
        key={`frequency-tabpanel-weekly`}
      >
        <ChoresTabPanel
          frequency={"weekly"}
          setAssignee={setAssignee}
          assignee={assignee}
          choresByAssignee={choresByAssignee}
        />
      </div>
      <div
        role="tabpanel"
        hidden={tabValue !== 2}
        key={`frequency-tabpanel-monthly`}
      >
        <ChoresTabPanel
          frequency={"monthly"}
          setAssignee={setAssignee}
          assignee={assignee}
          choresByAssignee={choresByAssignee}
        />
      </div>
      <div
        role="tabpanel"
        hidden={tabValue !== 3}
        key={`frequency-tabpanel-improvement`}
      >
        <ChoresTabPanel
          frequency={"improvement"}
          setAssignee={setAssignee}
          assignee={assignee}
          choresByAssignee={choresByAssignee}
        />
      </div>
      <div
        role="tabpanel"
        hidden={tabValue !== 4}
        key={`frequency-tabpanel-screens`}
      >
        <ChoresTabPanel
          frequency={"screens"}
          setAssignee={setAssignee}
          assignee={assignee}
          choresByAssignee={choresByAssignee}
        />
      </div>
      <div
        role="tabpanel"
        hidden={tabValue !== 5}
        key={`frequency-tabpanel-needs`}
      >
        <ChoresTabPanel
          frequency={"asNeeded"}
          setAssignee={setAssignee}
          assignee={assignee}
          choresByAssignee={choresByAssignee}
        />
      </div>
    </Box>
  )
}

export default DesktopChoresView;