export const prettyFormatDate = (dateString) => {
  if (!dateString) return "Unknown date"; // Handle missing values

  let date;

  // Check if the date is in "YYYY-MM-DD" format (ISO)
  if (/^\d{4}-\d{2}-\d{2}$/.test(dateString)) {
    // Create a local date by splitting the string
    const [year, month, day] = dateString.split("-");
    date = new Date(year, month - 1, day);
  }
  // Check if the date is in "YYYY-MM" format (Year-Month)
  else if (/^\d{4}-\d{2}$/.test(dateString)) {
    const [year, month] = dateString.split("-");
    date = new Date(year, month - 1);
    return new Intl.DateTimeFormat("en-US", {
      month: "long",
      year: "numeric",
    }).format(date);
  }
  // Check if the date is in "MMMM YYYY" (e.g., June 2019)
  else if (/^[A-Za-z]+ \d{4}$/.test(dateString)) {
    return dateString; // Already in the correct format
  }
  // Check if the date is in "MMMM DD, YYYY" format (e.g., June 14, 2001)
  else if (/^[A-Za-z]+ \d{1,2}, \d{4}$/.test(dateString)) {
    return dateString; // Already in the correct format
  }
  // If it's already a valid Date object
  else if (dateString instanceof Date) {
    date = dateString;
  }
  // Fallback for invalid formats
  else {
    return "Invalid date";
  }

  // If the date is invalid after parsing
  if (isNaN(date)) return "Invalid date";

  // Determine format based on input:
  // If the original string includes a comma or is longer than 7 characters, assume it includes a day.
  const options = {
    year: "numeric",
    month: "long",
    day: dateString.includes(",") || dateString.length > 7 ? "numeric" : undefined,
  };

  return date.toLocaleDateString("en-US", options);
};