import * as React from 'react';
import { useState } from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// Custom ExpandMore button with rotation effect.
const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function RichEvenCard({ event }) {
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded((prev) => !prev);
  };

  return (
    <Card sx={{ minWidth: 250, mb: 2}}>
      {/* Header with avatar and event title */}
      <CardHeader
        avatar={<Avatar alt={event.name} src={event.avatar} />}
        title={event.name}
        subheader={`${event.category.toUpperCase()} • ${event.family}`}
      />

      {/* Media with image and a gradient overlay for a richer look */}
      <Box sx={{ position: 'relative' }}>
        <CardMedia
          component="img"
          height="200"
          image={event.cardImage}
          alt={event.name}
          sx={{ objectFit: 'cover' }}
        />
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background: 'linear-gradient(to bottom, rgba(0,0,0,0.3), rgba(0,0,0,0.7))',
            opacity: 0.5,
          }}
        />
      </Box>

      {/* Main content with description and informational chips */}
      <CardContent>
        <Typography variant="body2" color="text.secondary">
          {event.description ||
            "A memorable event that brings the family together."}
        </Typography>
        <Box sx={{ mt: 1, display: 'flex', gap: 1 }}>
          <Chip label={event.category} size="small" />
          <Chip label={event.family} size="small" />
          {event.milestone && <Chip label="Milestone" size="small" color="primary" />}
        </Box>
      </CardContent>

      {/* Actions: Like, Share, and Expand More */}
      <CardActions disableSpacing>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>

      {/* Expandable section with additional details */}
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography variant="body2">
            {event.additionalDescription ||
              "More details about the event can be placed here. Include stories, location details, or any additional context that enriches the timeline experience."}
          </Typography>
        </CardContent>
      </Collapse>

      {/* Footer with formatted event date */}
      <Box sx={{ p: 2, pt: 0 }}>
        <Typography variant="caption" color="text.secondary">
          {new Date(event.date).toLocaleDateString()}
        </Typography>
      </Box>
    </Card>
  );
}