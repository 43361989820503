import { useState, useEffect } from "react"
import { doc, getDoc } from "firebase/firestore"
import { db } from "../../../firebase/firebase"

function useFamilyId(userId) {
  const [familyId, setFamilyId] = useState(null)

  useEffect(() => {
    console.log("Fetching Family Data in custom hook", userId)
    if (userId) {
      const fetchFamilyData = async () => {
        try {
          const userDocRef = doc(db, "Users", userId)
          const userDocSnap = await getDoc(userDocRef)

          if (userDocSnap.exists()) {
            const userData = userDocSnap.data()
            const familyId = userData.FamilyId
            setFamilyId(familyId)
            console.log("Family ID: ", familyId)
          }
        } catch (error) {
          console.error("Error fetching family data: ", error)
        }
      }

      fetchFamilyData()
    }
  }, [userId])

  return familyId
}

export default useFamilyId
