import React, { useEffect, useState } from "react"
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  MenuItem,
  IconButton,
  Box,
  Grid,
} from "@mui/material"
import { Add, Delete } from "@mui/icons-material"
import { init } from "aos"
import { saveCharacters } from "./advenchoreApi"

const characteristics = [
  "Curious",
  "Brave",
  "Mischievous",
  "Loyal",
  "Clever",
  "Resourceful",
  "Optimistic",
  "Impulsive",
  "Inventive",
  "Stubborn",
  "Strong",
  "Witty",
  "Mysterious",
  "Compassionate",
  "Sarcastic",
  "Reckless",
  "Wise",
  "Adventurous",
  "Quirky",
  "Determined",
  "Charming",
  "Enigmatic",
]

const FamilySetupDialog = ({
  open,
  onClose,
  onSubmit,
  initMembers,
  familyId,
}) => {
  const [members, setMembers] = useState(initMembers)

  useEffect(() => {
    setMembers(initMembers)
  }, [initMembers])

  const handleAddMember = () => {
    setMembers([
      ...members,
      {
        name: "",
        gender: "",
        characteristic: "",
        age: "",
        role: "",
      },
    ])
  }

  const handleDeleteMember = (index) => {
    const newMembers = [...members]
    newMembers.splice(index, 1)
    setMembers(newMembers)
  }

  const handleChange = (index, event) => {
    const { name, value } = event.target
    const newMembers = [...members]
    newMembers[index][name] = value
    setMembers(newMembers)
  }

  const onSubmitPress = () => {
    onSubmit(members)
    // submit the changes to firerstore
    saveCharacters(familyId, members)
    onClose()
  }

  const canSubmit =
    members.length > 0 &&
    members.every((member) =>
      Object.values(member).every((value) => value !== "")
    )

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Family Setup</DialogTitle>
      <DialogContent>
        {members.map((member, index) => (
          <Grid
            container
            spacing={2}
            alignItems="center"
            key={index}
            sx={{ p: 1 }}
          >
            <Grid item xs={12} sm={3}>
              <TextField
                fullWidth
                label="Name"
                name="name"
                value={member.name}
                onChange={(e) => handleChange(index, e)}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextField
                fullWidth
                select
                label="Gender"
                name="gender"
                value={member.gender}
                onChange={(e) => handleChange(index, e)}
              >
                <MenuItem value="male">Male</MenuItem>
                <MenuItem value="female">Female</MenuItem>
              </TextField>
            </Grid>
            <Grid item>
              <TextField
                fullWidth
                select
                label="Characteristic"
                name="characteristic"
                value={member.characteristic}
                sx={{ minWidth: 200 }}
                onChange={(e) => handleChange(index, e)}
              >
                {characteristics.map((characteristic) => (
                  <MenuItem key={characteristic} value={characteristic}>
                    {characteristic}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextField
                fullWidth
                type="number"
                label="Age"
                name="age"
                value={member.age}
                onChange={(e) => handleChange(index, e)}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextField
                fullWidth
                select
                label="Role"
                name="role"
                value={member.role}
                onChange={(e) => handleChange(index, e)}
              >
                <MenuItem value="mom">Mom</MenuItem>
                <MenuItem value="dad">Dad</MenuItem>
                <MenuItem value="brother">Brother</MenuItem>
                <MenuItem value="sister">Sister</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} sm={1}>
              <IconButton
                aria-label="delete"
                onClick={() => handleDeleteMember(index)}
                disabled={members.length === 1}
              >
                <Delete />
              </IconButton>
            </Grid>
          </Grid>
        ))}
        <Box mt={2}>
          <Button onClick={handleAddMember} startIcon={<Add />}>
            Add Member
          </Button>
        </Box>
      </DialogContent>
      <Box mt={2} mb={2} mr={2} display="flex" justifyContent="flex-end">
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item>
            <Button variant="outlined" onClick={onClose}>
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={onSubmitPress}
              disabled={!canSubmit}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  )
}

export default FamilySetupDialog
