// features/story/storySlice.js
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { simulateStoryGeneration } from '../views/inner-pages/StoryView/api';

import { getAuth } from 'firebase/auth'; 

const getChapterUrl =
  'http://127.0.0.1:5001/photo-organization-1e84f/us-central1/generate_next_chapter';

const directStoryUrl =
  'http://127.0.0.1:5001/photo-organization-1e84f/us-central1/temp_create_story';

const createStoryUrl =
  'http://127.0.0.1:5001/photo-organization-1e84f/us-central1/enqueue_story_task';


  export const initializeNewStory = createAsyncThunk(
    'story/initializeNewStory',
    async (storyData, thunkAPI) => {
      console.log("storyData", storyData);
  
      try {
        // Get the current user's ID token from Firebase Auth
        const auth = getAuth();
        const user = auth.currentUser;
        if (!user) {
          throw new Error('User not authenticated');
        }
        const idToken = await user.getIdToken();
  
        // Make the API call to the backend
        const response = await fetch(directStoryUrl, {
          // const response = await fetch(getStoryUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            idToken,
            storyData: storyData,
            characters: storyData?.characters,
            concept: "thriller",
            theme: "mystery",
            setting: "a cabin in the woods"

          }),
        });
  
        // Parse and return the response
        const result = await response.json();
        console.log("result", result);
        return result;
      
      } catch (error) {
        console.error("Error initializing new story:", error);
        return thunkAPI.rejectWithValue(error.message); 
      }
    }
  );

// generates next chapter with 'available' status
export const generateNewChapter = createAsyncThunk(
  'story/generateNewChapter',
  async (_, thunkAPI) => {
    const storyId = thunkAPI.getState().story.storyId;
    console.log("storyId", storyId);

    try {
      // Get the current user's ID token from Firebase Auth
      const auth = getAuth();
      const user = auth.currentUser;
      if (!user) {
        throw new Error('User not authenticated');
      }
      const idToken = await user.getIdToken();

      // Make the API call to the backend
      const response = await fetch(getChapterUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          idToken: idToken,  // Include the ID token
          storyId: storyId,  // Include the story ID
        }),
      });

      // Parse and return the response
      const result = await response.json();
      return result;

    } catch (error) {
      console.error("Error generating new chapter:", error);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

const initialState = {
  storyState: 'INIT_STATE',
  // currentChapter: 0,
  chapterData: [
    { id: 1, title: 'Chapter 1', status: 'locked' },
    { id: 2, title: 'Chapter 2', status: 'locked' },
    { id: 3, title: 'Chapter 3', status: 'locked' },
    // Add other chapters as needed
  ],
  currentPoints: 0,
  queuedChapter: 0,
  targetPoints: 100,
};

const getFirstLockedChapterIdx = (chapterData) => {
  return chapterData.findIndex((chapter) => chapter.status !== 'unlocked');
}

const getNextAvailableChapterIdx = (chapterData) => {
  return chapterData.findIndex((chapter) => chapter.status === 'available');
}

const storySlice = createSlice({
  name: 'story',
  initialState,
  reducers: {
    resetStoryState(state) {
      state.storyState = initialState.storyState;
      state.chapterData = initialState.chapterData;
      state.currentPoints = initialState.currentPoints;
      state.queuedChapter = initialState.queuedChapter;
    },
    unlockNextChapter(state) {
      const nextChapterIndex = getFirstLockedChapterIdx(state.chapterData);
      if (nextChapterIndex !== -1) {
        state.chapterData[nextChapterIndex].status = 'unlocked';
        // state.currentChapter = nextChapterIndex;
      }
    },
    updatePointTotals(state, action) {
      const { currentPoints, targetPoints } = action.payload;
      state.currentPoints = currentPoints;
      state.targetPoints = targetPoints;

      if (currentPoints >= targetPoints) {

      const nextChapterIndex = getFirstLockedChapterIdx(state.chapterData);
      if (nextChapterIndex !== -1) {
        state.chapterData[nextChapterIndex].status = 'available';
      }
        }
    },
    generateSimulatedNewStory(state) {
      // state.currentChapter = 0;
      state.chapterData = state.chapterData.map((chapter, index) => {
        if (index === 0) {
          return {
            ...chapter,
            ...simulateStoryGeneration(state.queuedChapter),
            status: 'unlocked',
          };
        }
        return {
          ...chapter,
          status: 'locked',
        };
      });
    },
    setStoryState(state, action) {
      state.storyState = action.payload;
    },
    setStoryId(state, action) {
      state.storyId = action.payload;
    },
    setChapterData(state, action) {
      console.log("action.payload in setChapterData", action.payload);
      state.chapterData = action.payload.chapterData;
      state.storyState  = "IN_PROGRESS";
    },
    setCurrentPoints(state, action) {
      state.currentPoints = action.payload;
    },
    setQueuedChapter(state, action) {
      state.queuedChapter = action.payload;
    },
    unlockChapter(state, action) {
      const updatedChapters = state.chapterData.map((chapter, index) => {
        if (index === action.payload) {
          return {
            ...chapter,
            ...simulateStoryGeneration(state.queuedChapter),
            status: 'unlocked',
          };
        }
        return chapter;
      });
      state.chapterData = updatedChapters;
      // state.currentChapter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(generateNewChapter.pending, (state) => {
        // Optionally, set some loading state
        state.loading = true;
      })
      .addCase(generateNewChapter.fulfilled, (state, action) => {
        // Update the state with the new chapter data
        console.log("action.payload", action.payload);
        const payload = action.payload
        if (state.storyState === "INIT_STATE") {
          state.storyState = "IN_PROGRESS";
        }
        //if the chapterId is the last chapter, set the storyState to "COMPLETED"
        if (Number(payload.chapterId) === state.chapterData.length - 1) {
          state.storyState = "STORY_COMPLETE";
        }
        state.chapterData = state.chapterData.map((chapter, index) => {
          if (index === Number(payload.chapterId)) {
            return {
              ...chapter,
              ...action.payload, // The new data from the API
              status: 'unlocked',
            };
          }
          return {
            ...chapter,
          };
        });
        state.loading = false; // Reset the loading state
      })
      .addCase(generateNewChapter.rejected, (state, action) => {
        // Handle any errors
        state.loading = false;
        state.error = action.payload; // Store the error message
      })
      .addCase(initializeNewStory.pending, (state) => {
        // Optionally, set some loading state
        state.loading = true;
      })
      .addCase(initializeNewStory.rejected, (state, action) => {
        // Handle any errors
        state.loading = false;
        state.error = action.payload; // Store the error message
      })
      .addCase(initializeNewStory.fulfilled, (state, action) => {
        // Update the state with the new story data
        // state.currentChapter = 0;
        state.storyState = "IN_PROGRESS";
        state.chapterData = state.chapterData.map((chapter, index) => {
          if (index === 0) {
            return {
              ...chapter,
              ...action.payload, // The new data from the API
              status: 'unlocked',
            };
          }
          return initialState.chapterData[index];
        });
        state.loading = false; // Reset the loading state
      })
      
  },
});

export const {
  resetStoryState,
  setStoryState,
  // setCurrentChapter,
  setChapterData,
  setStoryId,
  setCurrentPoints,
  setQueuedChapter,
  setShowDialog,
  setDialogMessage,
  setDialogTitle,
  setDialogCallback,
  unlockChapter,
  updatePointTotals,
} = storySlice.actions;

export default storySlice.reducer;