import { useState, useEffect } from "react"
import { doc, onSnapshot } from "firebase/firestore"
import { db } from "../../../firebase/firebase"

function useFamilyData(familyId) {
  const [currentStoryId, setCurrentStoryId] = useState(null)
  const [processing, setProcessing] = useState(false)

  useEffect(() => {
    if (!familyId) return
    const docRef = doc(db, "advenchore", familyId)
    const unsubscribe = onSnapshot(docRef, (doc) => {
      if (doc.exists()) {
        console.log(doc.data())
        setCurrentStoryId(doc.data().currentStoryId)
        setProcessing(doc.data().processing)
      }
    })

    return () => {
      unsubscribe()
    }
  }, [familyId])

  return { currentStoryId, processing }
}

export default useFamilyData
