import { Alert, Avatar, Divider, Grid, Stack, useMediaQuery } from "@mui/material";
import ChoresCard from "./ChoresCard";
import { doc, updateDoc, getDoc  } from "firebase/firestore";
import { db, useFirebaseAuthentication } from "../../../firebase/firebase";
import { avatars } from "./ChoresView";
import AddEditChoreDialog from "./AddEditChoreDialog";
import { useRef, useState } from "react"
import DeleteConfirmationDialog from "./DeleteConfirmationDialog"
import Snackbar from "@mui/material/Snackbar"
import { isChoreComplete } from "./utils"
import ConfettiTrigger from "../animation/ConfettiTrigger"
import AddOtherTasksDialog from "./AddOtherTasksDialog"

const AssigneeChoresSection = ({ chores, assignee, label }) => {
  const filteredChores = chores?.filter(
    (chore) => chore.category?.toLowerCase() === label?.toLowerCase()
  )
  const [openDialog, setOpenDialog] = useState(false)
  const [openTaskDialog, setOpenTaskDialog] = useState(false)
  const [newChore, setNewChore] = useState({
    id: null,
    description: "",
    assignee: "",
    frequency: "daily",
    category: label.toLowerCase(),
  })
  const [newTask, setNewTask] = useState({
    id: null,
    description: "",
    assignee: "",
    frequency: "daily",
    category: label.toLowerCase(),
  })
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [choreToDelete, setChoreToDelete] = useState(null)
  const [open, setOpen] = useState(false)

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"))
  const authUser = useFirebaseAuthentication()

  const confettiRefs = useRef([])

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return
    }
    setOpen(false)
  }

  const onCloseDeleteDialog = () => {
    setDeleteDialogOpen(false)
  }

  const onMarkDone = async (choreId) => {
    if (choreId === null) {
      return
    }
    try {
      const choreRef = doc(db, "chores", choreId)

      await updateDoc(choreRef, {
        last: new Date(),
      })
      // updateStoryProgress(choreId)
      // addChorePoints(1)
      console.log("Chore marked as done:", choreId)
    } catch (error) {
      console.error("Error updating document:", error)
    }
  }

  const addChorePoints = async (points) => {
    const payload = {
      familyId: "t2VCwg2DVD42gFr7utiC",
      points: points || 1,
    }

    const token = authUser.accessToken

    try {
      const response = await fetch(
        "https://on-add-points-blcpza7vla-uc.a.run.app",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          body: JSON.stringify(payload),
        }
      )

      if (response.ok) {
        console.log("POST request successful")
      } else {
        console.error("POST request failed")
      }
    } catch (error) {
      console.error("Error:", error)
    }
  }

  const updateStoryProgress = async (choreId) => {
    if (choreId === null) {
      return
    }
    try {
      const choreRef = doc(db, "chores", choreId)

      // get chore points
      const choreDoc = await getDoc(choreRef)
      const choreData = choreDoc.data()
      const points = choreData.points || 1

      // get story admin
      const adminRef = doc(db, "stories", "admin")
      const adminDoc = await getDoc(adminRef)
      const adminData = adminDoc?.data()
      const progress = adminData?.progress || 0

      // update story progress
      await updateDoc(adminRef, {
        progress: progress + points,
      })

      console.log("Story progress updated:", choreId)
    } catch (error) {
      console.error("Error updating document:", error)
    }
  }

  const handleDialogClose = () => {
    setOpenDialog(false)
  }

  const onEdit = (choreId) => {
    const choreToEdit = filteredChores.find((chore) => chore.id === choreId)
    if (choreToEdit) {
      if (
        choreToEdit.category === "daily" ||
        choreToEdit.category === "weekly" ||
        choreToEdit.category === "monthly"
      ) {
        setNewChore({
          id: choreToEdit.id,
          description: choreToEdit.description,
          assignee: choreToEdit.assignee,
          frequency: choreToEdit.frequency,
          category: choreToEdit.category,
        })
        setOpenDialog(true)
      } else {
        setNewTask({
          id: choreToEdit.id,
          description: choreToEdit.description,
          assignee: choreToEdit.assignee,
          category: choreToEdit.category,
        })
        setOpenTaskDialog(true)
      }
    }
  }

  const onDeleteRequested = (choreId) => {
    setChoreToDelete(choreId)
    setDeleteDialogOpen(true)
  }

  if (!assignee) {
    return <div />
  }

  const avatarSize = 200

  const fade =
    (!isMobile && assignee === "Connor") || (!isMobile && assignee === "Leah")
      ? 0.2
      : 0

  const handleMarkDone = (choreId, index) => {
    confettiRefs.current[index].triggerConfetti()

    // Call the original onMarkDone function
    onMarkDone(choreId)
  }

  return (
    <Grid
      item
      xs={12}
      sm={6}
      md={4}
      lg={2.4}
      justifyContent={"center"}
      key={assignee}
      sx={{ backgroundColor: `rgba(255,255,255,${fade})` }}
    >
      <Avatar
        src={avatars[assignee.toLowerCase()]}
        sx={{
          width: avatarSize,
          height: avatarSize,
          margin: "auto",
          marginTop: 2,
          marginBottom: 2,
        }}
        alt={assignee}
      />
      {/* {showSuccess && <Alert severity="success">Can Watch Screens!</Alert>} */}
      <Divider />
      <Stack spacing={0.25}>
        {filteredChores
          ?.sort((a, b) => a.description?.localeCompare(b.description))
          .map((chore, index) => (
            <ConfettiTrigger
              key={`${chore.id}-confetti`}
              ref={(el) => (confettiRefs.current[index] = el)}
            >
              <ChoresCard
                key={`${chore.id}-${index}`}
                id={chore.id}
                title={""}
                isComplete={isChoreComplete(chore)}
                description={chore.description}
                onMarkDone={() => handleMarkDone(chore.id, index)}
                onEdit={() => onEdit(chore.id)}
                onDelete={() => onDeleteRequested(chore.id)}
              />
            </ConfettiTrigger>
          ))}
      </Stack>
      {newChore.id !== null && (
        <AddEditChoreDialog
          open={openDialog}
          handleClose={handleDialogClose}
          chore={newChore}
          setChore={setNewChore}
        />
      )}
      {newTask.id !== null && (
        <AddOtherTasksDialog
          open={openTaskDialog}
          handleClose={() => setOpenTaskDialog(false)}
          chore={newTask}
          setChore={setNewTask}
          type={label}
        />
      )}
      <DeleteConfirmationDialog
        open={deleteDialogOpen}
        onClose={onCloseDeleteDialog}
        choreId={choreToDelete}
      />
      <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Chore deleted!
        </Alert>
      </Snackbar>
    </Grid>
  )
}

export default AssigneeChoresSection;